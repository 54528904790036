.medicalsklition_formsklition{
    width: 100%;
    background-color: #f4f4f4;
    border-radius: 10px;
    padding: 10px 0px;
}
.medicalfullwidth{
    display: flex;
    justify-content: center;
    align-items: center;
}
.medicalfullwidthQustions{
    width: 90%;
    height: 80px !important;
    border-radius: 10px;
}

.icici-med-person-div{
    display: flex;
    flex-direction: row;
    /* margin-top: 20px;
    margin-bottom: 10px; */
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 5px;

}

.care-wrap-persion{
    display: flex;
    flex-wrap: wrap;
}

.care-persion-div{
    margin-bottom: 10px;
}

.care-que label {
    display: flex;  
    align-items: flex-start !important;
}

.care-que label > span:first-of-type {
    padding-top: 3px !important;
}
