/* Styling for the select element */
.selectvalues {
  background-color: transparent !important;
  border: none;
  outline: none;
  padding: 0 5px;
  font-size: 1rem;
 padding: 2px;
}
.selectvalues option{
  background-color: rgb(218, 210, 210);

}
.helthtwoimagediv img{
margin-right: 10px;
width: 40px;
height: 40px;
 }
.selectmemberh6{
  color: rgb(105, 102, 102);
  margin: 10px 0;
}
.tooltipposition{
  position: relative;
}
.tooltips {
  position: absolute;
  right: -85px;
  top: 13px;
}
.radiohide{
  display: none;
}
.input-edit .internal-autofill-selected{
  background-color: white !important;
}

.health-step2-date >div >input{
  display: none;
}





@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}

/* Apply the shaking effect */
.shaking {
  animation: shake 0.5s ease-in-out 3;
}