.kycformdata{

  margin-bottom: 12px;
}
.kyc{
  font-size: 20px;
  position: relative;
  top: 2x;
  margin-top: 15px;
  font-size: 26px;
  font-weight: 600;
  text-transform: capitalize;

}
.kycTOPICS{
  color: #2959b8;
  background-color: #2959b84a;
  padding: 10px 16px;
  border-radius: 7px;
  font-weight: 500;
}
.line-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.kycseparator {
  margin: 0;
  padding: 0 10px; /* Adds spacing between the text and the lines */
  background-color: white; /* Matches the background color to "erase" the line behind the text */
  z-index: 1; /* Ensures the text is placed above the lines */
  position: relative;
  color: grey;
}

.kychr {
  flex-grow: 1; /* Ensures the line takes the full available width */
  border: 0;
  border-top: 2px dotted #ccc; /* Dotted line style with color */
  margin: 0;
}
.kycheader{
  color: #ef9b29;
  font-size: 1.2rem;
  position: relative;
  bottom: 10px;
  
}


.css-84nhb1-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  width: 0;
  min-width: 56px!important 


}

.kycform1 {
  height: 74px !important;
  width: 215px;
  border-radius: 10px !important;
  top: 100px;
  left: 36px;
}

.kycform2 {
  height: 74px !important;
  width: 215px;
  border-radius: 10px !important;
  top: 27px;
  left: 271px;
}

.kycform3 {
  height: 74px !important;
  width: 262px;
  border-radius: 10px !important;
  top: -44px;
  left: 507px;
}

.kycform4 {
  height: 74px !important;
  width: 215px;
  border-radius: 10px !important;
  top: -39px;
  left: 36px;
}
.kycform5 {
  height: 74px !important;
  width: 215px;
  border-radius: 10px !important;
  top: -114px;
  left: 270px;
}
.kycform6 {
  height: 74px !important;
  width: 262px;
  border-radius: 10px !important;
  top: -186px;
  left: 508px;
}

.kycform7 {
  height: 74px !important;
  width: 215px;
  border-radius: 10px !important;
  top: -114px;
  left: 36px;
}
.kycform8 {
  height: 74px !important;
  width: 215px;
  border-radius: 10px !important;
  top: -188px;
  left: 269px;
}
.kycform9 {
  height: 74px !important;
  width: 262px;
  border-radius: 10px !important;
  top: -262px;
  left: 508px;
}
.kycform10 {
  height: 74px !important;
  width: 215px;
  border-radius: 10px !important;
  top: -242px;
  left: 36px;
}
.kycform11 {
  height: 74px !important;
  width: 215px;
  border-radius: 10px !important;
  top: -315px;
  left: 269px;
}
.kycform12 {
  height: 74px !important;
  width: 268px;
  border-radius: 10px !important;
  top: -387px;
  left: 501px;
}
.kycseparator {
position: relative;
display: inline-block;
font-size: 20px;
font-weight: bold;
color: #ffffff; /* White text for contrast */
background-color: #0055a5; /* Light blue from your palette */
padding: 10px 20px; /* Add padding for better appearance */
border-radius: 8px; /* Rounded corners */
overflow: hidden; /* Ensure animation stays within bounds */
box-shadow: 0px 4px 8px rgba(0, 85, 165, 0.3); /* Subtle shadow matching #0055a5 */
}

.kycseparator::before {
content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: linear-gradient(
  90deg,
  transparent,
  rgba(255, 255, 255, 0.6),
  transparent
);
background-size: 200% 200%;
animation: loading 2s infinite; /* Original animation */
z-index: 1; /* Ensure it's above the background */
}

.kycseparator span {
position: relative;
z-index: 2; /* Ensure the text stays above the shimmer */
}

/* Original Animation */
@keyframes loading {
0% {
  background-position: 200% 0;
}
100% {
  background-position: -200% 0;
}
}








/* Container styles */
.line-container {
display: flex;
align-items: center;
justify-content: center;
position: relative;
margin: 20px 0; /* Space between the lines and content */
}

.kychr {
flex-grow: 1; /* Ensures the line takes the full available width */
border: 0;
border-top: 2px dotted #0055a5; /* Dotted line with your website's blue color */
margin: 0 15px; /* Adds spacing between the lines and the text */
}

/* Text and shimmer effect */
.kycseparator {
position: relative;
display: inline-block;
font-size: 20px;
font-weight: bold;
color: #ffffff; /* White text for contrast */
background-color: #0055a5; /* Light blue from your palette */
padding: 10px 20px; /* Add padding for better appearance */
border-radius: 8px; /* Rounded corners */
overflow: hidden; /* Ensure animation stays within bounds */
box-shadow: 0px 4px 8px rgba(0, 85, 165, 0.3); /* Subtle shadow matching #0055a5 */
}

.kycseparator::before {
content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: linear-gradient(
  90deg,
  transparent,
  rgba(255, 255, 255, 0.6),
  transparent
);
background-size: 200% 200%;
animation: loading 2s infinite; /* Shimmer animation */
z-index: 1; /* Ensure it's above the background */
}

.kycseparator span {
position: relative;
z-index: 2; /* Ensure the text stays above the shimmer */
}

/* Original Animation */
@keyframes loading {
0% {
  background-position: 200% 0;
}
100% {
  background-position: -200% 0;
}
}


.kyc-error-message {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e50318c7; /* Bright red background for error */
  color: #ffffff; /* White text for contrast */
  padding: 15px 20px; /* Spacing around the text */
  border-radius: 8px; /* Rounded corners for a clean look */
  font-size: 16px; /* Readable font size */
  font-weight: bold; /* Make the message stand out */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin: 20px 0; /* Add spacing around the error box */
}

.kyc-error-message p {
  margin: 0; /* Remove default margin from <p> */
}

.kyc-error-message span {
  margin-right: 5px; /* Add space between "Error:" and the message */
}

.kyc-ref-div{
  display: flex;
  justify-content: flex-start;
  padding: 0px;
  flex-direction: column;
  margin-left: 7px;

}
.kyc-referral-hr{
  width: 100%;
  border-top: 1px solid #8080806e;
}
.kyc-ref-inp{
  border-radius: 5px;
  border: 1px solid #8080806e;
  outline: none;
  padding: 5px;
  font-size: 12px;

}

.kyc-ref-inp:focus {
  border: 1px solid #ccc; /* Same border as normal */
  box-shadow: none; /* Prevent extra highlighting */

 

}

.kyc-ref-span{
  color: #2959b8;
  padding-left: 2px;
  text-shadow: 0 0 black;
}

.relnomfix{
  margin-bottom: 39px!important;
  margin-top: -40px!important;
}

.addfix{
  margin-top: -28px!important;
}

.commun-fix{
  margin-top: -33px!important;
}