.icici-question-box{
    border: 1px solid #80808061;
    padding: 11px;
    border-radius: 6px;
    box-shadow: 2px 2px 2px 1px #1e1e1e1a;
}

.icici-unclick-person{
    padding: 10px;
    border: 2px dashed #0d5eaa52;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
}

.icici-clicked-person{
    background-color: #2959b82b;
    border: 1px solid #2959b896;
    padding: 10px;
    border: 2px solid #0d5eaa52;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;

}