
/* Diffrence */
.sklitonfetch {
    border-radius: 14px 14px 12px 41px;
    border-width: 1px 1px 1px 22px;
    padding: 20px 0px;
    height: 137px;
    background-color: #eeeeee;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.SklitionAfterFetchimages{
    width: 83px;
    height: 83px !important;
}
.animationdivs1 {
    width: 199px;
    height: 35px !important;
    border-radius: 7px;
}

.animationdivs2 {
    width: 201px;
    height: 60px !important;
    border-radius: 7px;
}
.animationdivs3 {
    width: 155px;
    height: 58px !important;
    border-radius: 7px;

}
.summerySklition {
    background: #fcf9f942;
    border-radius: 10px;
    margin-bottom: 10px;
    height: 385px;
    background-color: #f4f4f4;
}
.headerSklition{
    padding: 10px;
}
.summeryheadtext {
    height: 31px !important;
    border-radius: 5px;
    width: 95%;
}
.summerysklitonbody{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.summeryAfterFetchimages {
    width: 72px;
    height: 70px !important;
}
.priceandtanureSummerySkalition {
    width: 221px;
    height: 59px !important;
    border-radius: 10px;

}
.sklitionbody2{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px;
}
.skalitionSummeryText1 {
    width: 95%;
    height: 30px !important;
    border-radius: 15px;

}
.summeryfooterbody {
    display: flex
;
    justify-content: center;
    align-items: end;
    height: 188px;
}
.skalitionSummeryText3 {
    width: 98%;
    height: 50px !important;
    border-radius: 15px;

}
.addonssklitions {
    width: 100%;
    height: 164px;
    margin-top: 10px;
    border-radius: 20px;
    background-color: #f4f4f4;
}
.paddingfixtenure{
    padding: 13px!important;
}
.addonslogoSklitions {
    height: 65px !important;
    width: 80px;
}
.tanureTermSkalitonText {
    width: 69%;
    height: 33px !important;
    border-radius: 15px;

}
.firstrowsklition {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px;
}
.tanureTermSkalitonTextdescription1 {
    height: 20px !important;
    width: 90%;
    border-radius: 11px;
    margin-bottom: 8px;
}
.tanureTermSkalitonTextdescription2 {
    height: 20px !important;
    width: 90%;
    border-radius: 11px;
}
.sendonrowsklition{
    display: block;
    padding: 0px 10px;
}
@media screen and (max-width: 600px){
    
    .summerySklition {
        background: #fcf9f942;
        border-radius: 10px;
        margin-bottom: 10px;
        height: 385px;
        background-color: #f4f4f4;
        margin-top: 18px;
    }
    
    
}