/* @import url('https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/css/bootstrap.min.css'); */

.rowleftsec {
  background: #fff;
  border: 15px solid #0055A5;
  border-radius: 12px 0 0 29px;
  border-width: 1px 0 1px 22px;
  padding: 13px;
  min-height: 120px;
}
@media screen and (max-width: 509px) {
  .rowleftsec {
    background: white;
    border-radius: 12px 0px 0px 29px;
    padding: 13px;
    border: solid 15px rgb(0 85 165);
    border-width: 7px 1px 1px 2px;
  }
}

.bluebg {
  border-color: rgb(0 85 165) !important;
}

.editp {
  margin: 10px;
  font-weight: 500;
  font-size: 0.9rem;
}

.edit {
  background: #f9f9f9;
  padding: 0;
  border-radius: 8px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid #0166b3;
}
.hight {
  margin: 0 auto;
}
.Planename {
  color: #0055a5;
  font-size: 1.1rem;
  margin-bottom: 0px;
  position: relative;
  top: 5px;
  font-family: "Poppins";
}

/* Define the animation */
@keyframes slideDown {
  0% {
    transform: translateY(-100%); /* Start completely off the screen */
    opacity: 0; /* Initially hidden */
  }
  100% {
    transform: translateY(0); /* End at the original position */
    opacity: 1; /* Reveal the element */
  }
}

.animation {
  animation: slideDown 0.5s ease-in-out; /* Adjust duration and easing as needed */
}

.rowrightsec {
  background: white;
  padding: 0px 0px 0px 0px;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 18%);
  border-radius: 0px 10px 10px 10px;
  border: solid 1px #0055a5;
  border-width: 1px 0 0 0;
}

h1 {
  font-size: 26px;
  font-weight: 600;
  text-transform: capitalize;
}

.offer {
  display: flex;
  align-items: center;
  text-align: center;
  /* flex-direction: column; */

  /* justify-content: space-between; */
}

.offertext {
  padding: 4px 4px;
  margin: 10px 0px 1px 9px;
  /* box-shadow: 0px 0px 2px rgb(0 0 0 / 40%); */
  width: auto;
  border-radius: 5px;
  text-align: center;
  font-size: 0.7rem;
  font-weight: 500;

  align-items: center;
  background-color: #0055a52b;
  border-radius: 5px;
  color: #0055a5;
  font-size: .7rem;
  padding: 3px 5px;
  margin-right: 20px;
  
}

.featueretag{
  align-items: center;
  background-color: #0055a52b;
  border-radius: 5px;
  color: #0055a5;
  font-size: .7rem;
  padding: 3px 5px;
 
}

.viewalloffers {
  color: #d78c29;
  background-color: #faebd7ad;
  text-decoration: underline;
  box-shadow: none !important;
  position: relative;
  top: -1px;
  font-size: 0.7rem;
  text-wrap: nowrap;
  margin-left: -26px;
  text-decoration: none;
  vertical-align: middle;
  padding-top: 7px;
  cursor: pointer;
}

.planvideo{
  width: fit-content;
  padding: 7px;
  background-color: #9ad1a32b;
  color: forestgreen;
}

.feature-div{
  width: 179px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sticky-top {
  z-index: 0!important;
}

.plan-compare-add{
  background-color: #ee20081c;
  width: fit-content;
  border: 2px dotted #bc050599;
  border-radius: 4px;
  text-shadow: 0 0 black;
  cursor: pointer;
}

.plan-compare-add label{
  color: rgba(208, 11, 11, 0.784);
  cursor: pointer;
}

.plan-compare-add input{
  cursor: pointer;
}

.compare-inp-plan{
margin: 5px;
}


.plan-compare-add{
  /* margin-left: -25px;
  margin-top: 5px; */
}
.maxbtnwidth{
  width: 50%;
}
.video-h5{
  font-family: "poppins";
    color: #2959b8;
    font-weight: 600;
    text-shadow: 0 0 black;
    margin-left: 5px;
}
.custom-control.custom-radio {
  display: flex;
  align-items: center;
  margin: 14px 2px;
}

.custom-radio .custom-control-input {
  width: 1.5em;
  height: 1.5em;
}

/* Increase radio button label size */
.custom-radio .custom-control-label::before {
  width: 1.5em;
  height: 1.5em;
}

.love {
  background: #cff4ff;
  border-radius: 15px 60px 12px 60px;
  height: 60px;
  /* padding-left: 10px; */
  padding-bottom: 15px;
}
.love p {
  font-size: 0.75rem;
  text-align: center;
}

.icol {
  color: red;
  font-size: 20px;
}

h1.cover {
  font-size: 28px;
  font-weight: 700;
}

.btnmonth {
  border: none;
  border-radius: 13px;
  background: rgb(0 85 165);
  color: white;
  font-weight: 600;
  width: 100%;
  height: 43px;
  font-family: "Poppins";
  font-size: 0.9rem;
}
.AnualCharages {
  font-size: 0.8rem;
  color: rgb(59, 58, 58);
}

.downborder {
  border: solid #b3b3b3;
  border-width: 0px 0px 2px 0px;
  border-radius: 10px;
}

.top-love {
  color: blue;
  border-radius: 15px 60px 15px 60px;
  padding: 2px 2px;
  background-color: #fd920247;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  margin: 5px 0;
}
.reccardline {
  align-items: center;
  border-bottom: 1px dashed gray;
  border-left: 1px dashed gray;
  border-radius: 0 0 0 15px;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  display: flex;
}
.top-love p {
  font-size: 0.7rem;
  color: rgb(0 0 0 / 81%);
  position: relative;
  top: 7px;
  font-family: "Poppins";
}
.top-love i {
  color: rgb(34, 36, 34);
  margin: 0 8px;
}
.coveramount {
  font-size: 0.7rem;
  font-family: "Poppins";
  color: #605d5d;
  margin-bottom: 0;
  text-align: center;
  font-weight: 600;

}
.cashlesshospitals {
  font-size: 0.7rem;
  font-family: "Poppins";
  color: #605d5d;
  margin-bottom: 0;
  font-weight: 600;
}

.selectammount {
  appearance: none;
  border: 1px solid #6b6868;
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
  color: #555;
  width: 200px; /* Adjust width as needed */
  cursor: pointer;
  outline: none;
  transition: background 0.3s ease, color 0.3s ease, border 0.3s ease;
  -webkit-appearance: auto;
}

.selectammount option {
  border: 1px solid #ddd;
  border-radius: 20px;
}

.selectammount option:checked,
.selectammount option:active {
  background: #efefef;
  color: #2c2b2b;
}

.first-skeleton{
    width: 100%;
    margin-bottom: 10px;
    border-radius: 10px;
    background: #ddd;
    border: 5px solid grey;
    border-radius: 12px 0 0 29px;
    border-width: 1px 0 1px 1px;
    padding: 13px;
    min-height: 120px;
    border-bottom-left-radius: 40px;
    background-color: rgb(128 123 123 / 12%)!important;

}

.logo-skel{
  position: absolute;
  width: 90px;
  margin: auto;
  border-radius: 10px;
  margin: 20px;
  clip-path: path("M 10.19 5.94 V 35.64 C 10.19 53.46 24.82 65.34 36.7 71.28 C 48.58 65.34 60.46 53.46 60.9 35.2 V 5.94 Z");
  /* clip-path: path("M5.8 10.8 V64.8 C5.8 27 9 33 15 36 C 21 33 27 27 27 18 V 3 Z"); */
  /* clip-path: path("M5.8 10.8V64.8C5.8 97.2 32.4 118.8 54 129.6 75.6 118.8 97.2 97.2 97.2 64.8V10.8Z"); */
  /* transform: rotate(180deg); */
  width: 70px;
  height: 80px !important;
  /* clip-path: polygon(50% 0%, 100% 30%, 97% 100%, 16% 100%, 10% 30%); */
  /* clip-path: path("M0 0 L100 0 L100 70 C80 100, 20 100, 0 70 Z");
  
/* } */
}




.sec-skeleton{
  width: 100%;
  background: white;
  padding: 0px 0px 0px 0px;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 18%);
  border-radius: 0px 10px 10px 10px;
  border: solid 1px grey;
  border-width: 1px 0 0 0;
  background-color: rgb(128 123 123 / 12%) !important;
  min-height: 200px;
  margin-bottom: 0px!important;

}

.left-main-skel{
  position: absolute!important;
  width: 100%;
}

.right-main-skel{
  position: absolute!important;
  width: 100%;
}

.heading-skel{
  position: absolute;
  width: 40%;

  margin: 10px!important;
}

.compare-box-skel{
  position: absolute;
  bottom: 0;
  width: 20%;
  margin: 5px;
  margin-bottom: 20px!important;
  margin-left: 10px;
}



.sub1{
  position: absolute;
  width: 40%;

  margin: 10px;
  top: 20%;
  width: 30%;
  margin-bottom: -5px!important;
  

}
.sub2{
  position: absolute;
  width: 40%;

  margin: 5px;
  top: 35%;
  width: 86px;
  margin-bottom: -5px!important;
  
}
.sub3{
  position: absolute;
  width: 40%;
  margin: 5px;
  top: 50%;
  width: 102px;
  margin-bottom: -5px!important;
}



.heading-right{
  position: absolute;
  width: 47%;
  
  right: 0;
 
  margin-top: 5px!important;
}
.cover-div-data {
  padding: 0px;
    position: absolute;
    right: 0px;
    display: flex;
    width: 50%;
    flex-direction: row;
    top: 50px;
    justify-content: space-around;
    
}

.sub-data-left{

 
  width: 100%!important;

  margin: 0px!important;
 

}
.sub-data-right{
 
 
  width: 100%!important;

  
  margin: 0px!important;
  

  



}

.price-amt{
  /* position: absolute; */
  position: relative;
  width: 50%;
  right: 0;
  bottom: 0;
  border-radius: 20px!important;
  margin-left: 49%!important;
  margin-top: 20%!important;
  
}

.skel-main-div{
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  
}


@media  screen and (max-width:768px) {

  .skel-main-div{
    flex-direction: column;
  }

  .first-skeleton {
    border: none;
    border-radius: 0px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    /* margin-bottom: -24px; */
    margin-bottom: -1px;
    width: 100%;
    height: 60px;
  
  }
  .logo-skel {
    position: absolute;
    right: 100px;
    top: 21px;
}
  .sec-skeleton{
    margin-bottom: 10px;
    border-radius: 10px 10px 10px;
    margin-top: -2px;
    background-color: rgb(0 0 0 / 10%);
    border-top: none!important;
    margin-bottom: 0px!important;
    
  }
  .right-main-skel {
    position: absolute;
    left: 0px;
    width: 100%;
    top: 115px;
    display: flex;
    height: fit-content;
}
}

@media screen and (max-width:993px){
 .left-skel-data{
  display: flex;
    flex-direction: row;
    position: absolute;
    top: 50px;
    width: 100%;
    justify-content: space-around;
 }
 .sub1{
  left: 0;
  width: 25%;
 }
 .sub3{
  right: 0;
  width: 25%;

 }
 .sub2{
  width: 25%;
 }

 .right-main-skel {
  position: absolute;
  left: 0px;
  width: 100%;
  top: 85px;
  display: flex;
  height: fit-content;
}
.price-amt {
  /* position: absolute; */
  position: relative;
  width: 50%;
  right: 0;
  bottom: 0;
  top: 65px;
  border-radius: 20px !important;
  margin-left: 49% !important;
  margin-top: 20% !important;
}
.compare-box-skel {
  position: relative;
  top: 177px;
  width: 38%;
  margin: 5px;
  margin-bottom: 20px !important;
  margin-left: 10px;
}
 .heading-right{
  position: absolute;
    width: 96%;
    /* right: 0; */
    /* right: 30px; */
    margin-top: 15px !important;
 }
 .sec-skeleton{
  min-height: 290px;
  margin-bottom: 0px!important;
 }

 .cover-div-data{
  width: 100%;
  margin-top: 20px;
 }

 .sub-data-left{
  width: 60%!important;
  margin-left: 20%!important;
 }
 .sub-data-right{
  width: 60%!important;
  margin-left: 20%!important;
 }

 .logo-skel{
  min-height: 68px;
 }
  
}







@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1250px !important;
  }
}

@media only screen and (max-width: 768px) {
  .rowleftsec {
    /* padding: 10px; */
    margin: 0px 10px 10px 10px;
    border-radius: 10px;
    width: 93%;
    position: relative;
    bottom: 3px;
  }

  .rowrightsec {
    margin: 10px;
    border-radius: 10px;
  }

  .rowrightsec {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    bottom: 50px;
  }
}

.faaroc10px {
  margin-left: 4px;
  /* content: "\f054"; */
  font-size: 10px;
}

.editp {
  margin: 10px;
  font-weight: 500;
  font-size: 0.9rem;
  font-family: "Poppins";
}

button.editb {
  border: none;
  background: #0166b3;
  font-weight: 500;
  padding: 10px 10px;
  border-radius: 3px;
  color: white;
  font-family: "Poppins";
}

.showbarresult {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterst {
  background: white;
  border: solid 1px #0000ff2b;
  padding: 5px;
  border-radius: 6px;
  margin: 14px;
  padding: 9px 14px;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  border: 1px solid #494c4f;
  color: #494c4f;
  position: relative;
}
.hight {
  padding-top: 25px !important;
}

.radio-set {
  display: flex;
  flex-direction: column;
  /* Display items vertically */
}

.companycheckinput {
  color: green;
  margin: 0 15px;
  width: 20px;
  height: 20px;
}

.radio-set label {
  margin-bottom: 8px;
}

.companylabel {
  margin: 7px 0px;
  border: 1px solid rgb(78, 78, 78);
  border-radius: 7px;
  margin: 7px 0px;
  color: gb(78, 78, 78);
  display: flex;
  padding: 10px 0;
  align-items: center;
}
.activelabel {
  background-color: transparent;
  border: 1px solid green;
  border-radius: 7px;
  margin: 7px 0px;
  color: green;
  display: flex;
  padding: 10px 0;
  align-items: center;
}

.r-dbg {
  background: white;
  border-radius: 9px;
  box-shadow: 203px 0 60px 1608px #00000063;
  padding: 26px 10px;
  position: absolute;
  width: 249px;
  z-index: 9999;
  left: 0;
  top: 100%;
  margin: 1px 0;
}
.applyfilter {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
}
.applyfilter .applybutton {
  color: white;
  background-color: rgb(0 85 165);
  border-radius: 5px;
  padding: 10px 10px;
  width: 48%;
  border: none;
  margin: 0 5px;
  font-size: 0.8rem;
  text-align: center;
}

@media (min-width: 992px) {
  .col-lg-1-c {
    flex: 0 0 auto;
    width: 20%;
  }
}

.wid12 {
  width: 13.666667% !important;
}

.activeradio {
  background-color: #0055a5;
}

.activeradio {
  background-color: #0055a5;
  padding: 10px 5px;
  border-radius: 5px;
  color: rgb(255, 255, 255);
  transition: 0.3s;
  text-align: center;
}

.h1 {
  font-size: 1.4rem;
  font-weight: 700;
  position: relative;
  top: 5px;
  color: rgb(0 85 165);
}

.radio-optionfilter {
  /* Hide default radio button */
  position: absolute !important;
  opacity: 0 !important;
  pointer-events: none !important;

  /* Define the custom square */
  width: 20px !important;
  height: 20px !important;
  border: 2px solid #333 !important;
  border-radius: 4px !important;
  outline: none !important;
  cursor: pointer !important;
}

/* Style the custom square for checked state */
.radio-optionfilter:checked + label::before {
  content: "" !important;
  display: block !important;
  width: 14px !important;
  height: 14px !important;
  background-color: #333 !important;
  border-radius: 2px !important;
}

.radio-option {
  /* Hide default radio button */
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  width: 20px !important;
  /* Set width and height for the square */
  height: 20px !important;
  border: 2px solid #333 !important;
  /* Add border for the square */
  border-radius: 4px !important;
  /* Optional: Add some border radius for rounded corners */
  outline: none !important;
  /* Remove focus outline */
  cursor: pointer !important;
  /* Change cursor on hover */

  /* Style for checked state */
  &:checked {
    background-color: #333 !important;
    /* Change background color when checked */
  }

  /* Style for hover state */
  &:hover {
    background-color: #ddd !important;
    /* Change background color on hover */
  }
}

.flexdircol {
  /* justify-content: space-between;
  display: flex;
  flex-direction: column; */
  padding-top: 7px;
}
.checkdivs{
  text-align: start;
}
.flexbtnplanepage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 25px;
}
.bgred {
  background-color: rgb(40 107 173 / 8%);
  color: #0055a5;
  border: none;
}

i.fa.fa-chevron-down {
  font-size: 14px;
  font-weight: 100;
}

.selectammount {
  width: 100%;
  border-radius: 3px;
  padding: 1px 5px;
  border: none;
  text-align: center;
  font-family: "Poppins";
  font-size: 0.9rem;
}

.font11 {
  /* color: rgb(43, 42, 40); */
  font-size: 11px;
  font-weight: bolder;
  color: #04684fed;
  min-width: 73px;
}

.righttop {
  background: #b1a8a51c;
  width: 100%;
  padding: 8px 4px;
  border-radius: 10px;
  margin-top: 9px;
}

i.fa.fa-user-circle-o.user {
  font-size: 30px;
}

.rightdown {
  text-align: left !important;
  margin: 10px 0;
  padding: 20px 0px;
  height: 200px;
  background: aliceblue;
  border-radius: 10px;
}

header.custome-head.fixed {
  z-index: 99999 !important;
}

.float {
}

.full-screen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  /* semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Make sure it appears on top of everything */
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fixedsidebar {
  position: fixed;
  top: 0px;
  right: 0;
  background: #ffffff;
  width: 400px;
  height: 100%;
  /* z-index: 99999999999999999999; */
  border: solid rgb(0 85 165 / 28%);

  border-width: 0 0px 0 2px;
  transition: right 0.5s ease;
  margin-top: 0px!important;
}

.fixedsidebar-off {
  position: fixed;
  top: 0px;
  right: -400px;
  background: #ffffff;
  width: 400px;
  height: 100%;
  z-index: 99999999999999999999;
  border: solid rgb(0 85 165 / 28%);

  border-width: 0 0px 0 2px;
  transition: right 0.5s ease;
}

.inscardicon {
  font-size: 20px;
  width: 45px;
  height: 45px;
  border: 1px solid #0055a5;
  /* Change color and thickness as needed */
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0055a5;
}

.inscardp {
  font-size: 15px;
  font-weight: 500;
  color: #0055a5;
}

.inscard {
  background: #007bff0e;
  margin: 15px 0px;
  padding: 11px 0;
  border-radius: 11px;
  box-shadow: 2px 2px 4px 1px #1b575430;
}

.inscard:hover {
  background: #007bff3d;
}

.scrollable-container {
  width: 100%;
  max-height: calc(80vh - 100px);
  overflow-y: auto;
  scrollbar-width: thin;
  padding: 7px;
}
.scrollable-container::-webkit-scrollbar {
  width: 5px;
}
.nextmember {
  padding: 0px 15px;
}
.sidbtnn {
  width: 98%;
  border: none;
  padding: 8px;
  border-radius: 9px;
  color: white;
  /* font-weight: 700; */
  margin: 0 5px;
  background-color: #033f99;
  border: 1px solid #afa5a561;
  /* font-family: "Proxima Nova Lt"; */
  font-size: 17px;
}

/* //after fatch plaan */

.nfh1 {
      color: #fd9202;
    font-size: 14px;
    font-weight: 600;
    margin: 7px 0;
    padding: 5px;
    text-align: left;
  font-family: "Poppins";
}


/* .summary-sum {
  margin-bottom: 6px;
  background-color: navajowhite;
  padding: 5px;
  border-radius: 3px;
} */
.summary-tenure {
  background-color: lavenderblush;
  padding: 5px;
  width: 100%;
  text-align: center;
}
.after-view {
  color: #2c990a;
}
.after-view:hover {
  color: rgb(20, 61, 196);
}

.afterlogo {
  color: #2959b8;
  font-weight: 700;
}

.hospitalnum {
  color: green;
  margin: 5px;
}
.reccard-des {
  color: #2959b8;
  font-size: 0.9rem;
  margin: 0 5px;
  margin-top: 10px;
  font-family: 'Poppins';
  font-weight: 600;
  margin-bottom: 11px;
}

.reccard-img {
  max-width: 140px;
}

.addonname {
  margin-top: 6px;
  text-align: left;
}

.addon-extra-hdfc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cash {
  font-size: 0.8rem;
  color: rgb(77 74 74);
  letter-spacing: 1px;
  font-family: "Poppins";
}
.nextfatchtop {
  border: solid 1px rgb(41 89 184);
  border-radius: 14px 14px 12px 41px;
  border-width: 1px 1px 1px 22px;
  padding: 20px 0px;
}
.nextimg img {
  width: 80%;
  height: 62px;
  margin: 7px;
  object-fit: contain;
  aspect-ratio: 3 / 2;
}

.nexcont {
  border: solid 4px #faa61a;
  border-width: 0 0 0 1px;
  padding: 25px 0px 25px 15px;
  border-radius: 0 0 0px 0px;
  border-left-width: 2px;
}
.colortex {
  color: rgb(110 106 106);
  font-size: 10pt;
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: 'Poppins';
}
.para {
  /* width: 100%;
  color: #424040;
  display: flex;
  font-family: Poppins;
  font-size: 10pt;
  display: flex;
  justify-content: center; */
  width: 100%;
    color: #00000075;
    display: flex;
    font-family: Poppins;
    font-size: 10pt;
    display: flex;
    justify-content: center;
    font-weight: 600;

}

.para-unchecked{
  width: 100%;
    color: #00000075;
    display: flex;
    font-family: Poppins;
    font-size: 10pt;
    display: flex;
    justify-content: center;
    font-weight: 400;
}

.para-relation{
  color: #0055a5d1;
    margin-left: 4px;
}
.para-rel{
  margin-left: 4px;
  color: #b208078f;
}
.ppriod {
  border: solid 1px #00000038;
  border-radius: 7px;
  margin: 15px 15px;
  /* padding: 5px 7px 4px 45px; */
  padding: 20px;
  padding-left: 40px;
  box-shadow: 0 4px 8px 0 rgba(27, 104, 167, 0.2),
    0 6px 20px 0 rgba(33, 114, 168, 0.19);
  min-width: 150px;
  cursor: pointer;

}

.oneshowdata {
  border: 3px solid rgb(11, 56, 122);
  box-shadow: 4px 3px 0 1px #2959b8;
  border-radius: 7px;
  cursor: pointer;
}
.nextsection {
  border: solid 1px rgb(0 0 0 / 19%);
  border-radius: 14px;
  margin-bottom: 7px;
  padding: 23px 15px;
  margin-top: 10px;
}

.afterplan-strong {
  font-weight: bolder;
  font-size: 15px;
  font-family: sans-serif;
  /* margin-left: -30px */
}
.ribbon {
  border-width: 2px 0;
  position: relative;
  display: block;
  background-color: #faa61a7d;
  border-radius: 5px;
  padding: 0px 8px;
}

.ribbon:before {
  content: "";
  display: inline-block;
  border-bottom-width: 2px;
  border-left-width: 2px;
  transform: rotate(-135deg);
  transform-origin: 0% 100%;
  position: absolute;
  top: -50%;
  bottom: 50%;
  left: 25px;
  right: 93px;
  background-color: white;
}

.psub {
  color: #000000c2;
  font-size: 13px !important;
  font-weight: 400;
}
.nextadd {
  background: transparent;
  border: solid 1px #0357a6;
  border-radius: 6px;
  padding: 5px;
  width: 105px;
  color: #0357a6;
}
.nextadd:hover {
  background: #0357a6;
  border: solid 1px #0357a6;
  border-radius: 6px;
  padding: 5px;
  width: 105px;
  color: white;
}
.ridecard {
  border: solid 1px rgb(0 0 0 / 19%);
  border-radius: 8px;
  padding: 15px;
  margin: 20px 0;
}
.reccard {
  background: #fff;
  border-bottom-width: 7px;
  box-shadow: 0 0 1px 0 #000000e6;
  margin: 18px 8px;
  padding: 12px 19px;
  border: 1px solid #2959b8;
  border-radius: 24px;
  box-shadow: 5px 5px 0px 1px #2959b8;
  height: fit-content;
  min-height: 130px;
}

.recimg {
  /* border-radius: 100%; */
  width: 100%;
  height: 62px;
  margin: 7px;
  object-fit: contain;
  aspect-ratio: 3 / 2;
}
.recbtn {
  background-color: #0055a5;
  border: 1px solid #0055a5;
  border-radius: 5px;
  color: #fff;
  height: 35px;
  padding: 5px 0;
  /* width: 100px; */
  width: 143px;
}
.buttonaddon{
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonaddon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}


.des-span {
  color: #7a7777;
  font-weight: 400;
  margin-left: 4px;
  font-size: 11pt;
  font-family: 'Poppins';
}
/* .recbtn:hover {
  background: #faa61a;
  color: white;
} */

.summer {
  background: #fcf9f942;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 0px #27282a;
  position: sticky;
  top: 67px;
  margin-bottom: 10px;
}
.summeryheding {
  background: #2959b8;
  color: white;
  padding: 9px 15px;
  border-radius: 10px 10px 0 0px;
}

.sumbox {
    background: #fff;
    border: 2px dashed #0d5eaa52;
    border-radius: 6px;
    padding: 3px 0;
    text-align: center;
}
.sumboxes {
  background: #fff;
  border: 1px dashed #0d5eaa52;
  border-radius: 6px;
  padding: 13px 0;
  margin-top: 10px;
  width: 100%;
}

.summerysec {
  margin: 22px 0;
}
.sumtot {
  padding: 0px 0px !important;
  margin: 0 auto;
}
.sumbtn {
  background: #fd9202;
  border: navajowhite;
  width: 100%;
  border-radius: 5px;
  font-weight: 600;
  padding: 10px;
  position: relative;
  top: 10px;
  color: white;
  transition: 0.3s;
  font-family: "Poppins";
  font-size: 10pt;
}
.topaddons {
  margin: 0px 0;
}
.sumbtn:hover {
  background: #e7972e;
  border: navajowhite;
  width: 100%;
  border-radius: 5px;
  font-weight: 700;
  padding: 10px;
  position: relative;
  top: 10px;
  color: white;
}

.sumbtn:hover {
  color: white;
}
.marginsum {
  padding: 5px 12px;
}
.primiumamount {
  position: relative;
  top: 12px;
}
.prpfbd {
  padding: 5px 10px;
  border-radius: 8px;
  margin: 0 5px;
  width: 100%;
  /* padding: 5px 20px; */
}
.css-v4u5dn-MuiInputBase-root-MuiInput-root:before {
  border: none !important;
}
.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  color: #0357a6 !important;
}
fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
}
/* .MuiStack-root.css-4jnixx-MuiStack-root {
  padding-top: 2;
  width: 30%;
  
} */


.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  /* padding: 8.5px 14px !important; */
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: #0357a6 !important;
}
.css-1dmqq7i-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input.css-1dmqq7i-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input.css-1dmqq7i-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input {
  /* padding-right: 24px; */
  /* min-width: 203px !important; */
}
.pmd {
  border: solid 1px rgb(0 0 0 / 19%);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 2px 2px 2px 1px #1e1e1e1a;
  margin: 10px 0;
}
.ppv10 {
  padding: 0px 10px;
}
.sumboxone {
  background: #e5b96f;
 
}
.members {
  padding: 10px 10px;
  background-color: black;
}

/* Eadit Details Page On Eadit seach page page is Starting Hear hear is Main Page isshue */

.inputcheck {
  background-color: transparent;
  border-radius: 5px 0px 0px 5px;
  border: none;
  padding: 10px 10px;
  display: flex;
}
.inputcheck .checkpolicy {
  width: 20px;
  height: 20px;
  color: #0055a5;
}

.checked-member .checkpolicy {
  width: 20px;
  height: 20px;
  color: #0055a5;
}

.checked-member span {
  vertical-align: middle;
}

.groupdata {
  margin: 15px 0;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  /* border: 1px solid #c1941336; */
  background-color: #d9d7d387;
  /* padding: 5px; */
}


.checkbox-div{
  align-items: flex-start!important;
}

.checkbox-div-box{
  padding-top: 3px!important;
}
@media screen and (max-width: 509px) {
  .recbtn {
    background-color: #0055a5;
    border: 1px solid #0055a5;
    border-radius: 5px;
    color: #fff;
    height: 35px;
    padding: 5px 0;
    width: 100%;
    margin-bottom: 17px;
}

  .reccardline {
    align-items: center;
    border-bottom: 1px dashed gray;
    border-left: 1px dashed gray;
    border-radius: 0 0 0 15px;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    display: block;
}
.reccard-img {
  max-width: 100%;
}
.addonname {
  margin-top: -15px;
  text-align: center;
}
  .groupdata {
    margin: 15px 0;
    border: 2px solid #e9c212;
    border-radius: 10px;
    padding: 5px 0;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0);
    background-color: #e9c2123d;
    display: flex;
    justify-content: space-between;
  }
}
.membersselect {
  color: rgb(104 96 96);
  font-weight: bold;
  margin-left: 15px;
}
.flexible {
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
  height: 40px;
  align-items: center;
}
.textaddonname{
  width: 100%;
}

@media screen and (max-width: 509px) {
  .flexible {
    display: flex;
    justify-content: space-between;
    margin-left: 15px;
    align-items: center;
  }
}
.plusminus {
  background-color: transparent;
  font-size: 1rem;
  border: 1px solid #005cc8;
  color: rgb(255, 255, 255);
  background-color: #005cc8;
}

.inc-dec-div {
  display: flex;
  flex-direction: row;
}
.ageselect {
  width: 80px;
  background-color: transparent;
  border: transparent;
  outline: none;
}
.persentagelogo {
  width: 20px;
  margin-right: 5px;
}
.offeerspan {
  color: #2134d8;
  font-size: 0.7rem;
  font-family: "Poppins";
  margin: 0 7px;
}
.form-check-label {
  font-size: 0.7rem;
  margin-right: 5px;
  font-family: "Poppins";
  /* margin-bottom: 5px; */
  position: relative;
  bottom: 3px;
}
.cashlesshospitalp {
  color: green;
  font-size: 1rem;
  font-weight: bold;
}

@media screen and (max-width: 509px) {
  .maindiv {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    height: 80px;
  }
}

/* Add the styles for the dropdown menu if needed */

/* MedicalDetails Css */

.proposalnameDiv {
  width: 100%;
  text-align: center;
  margin: 10px 0;
}

.proposalname {
  width: 100%;
  margin: 0 10px;
  color: rgb(74 70 70);
}
.boxdiv {
  width: 90%;
  /* border: 1px solid #0c0c0c; */
  border: 1px dashed #0d5eaa52;
  padding: 10px 5px;
  border-radius: 10px;
  background-color: #ffffff25;
  margin: 10px 0px;
  cursor: pointer;
}
.sumboxone {
  background-color: #2959b82b;
  border: 1px solid #2959b896;
  border-radius: 6px;
  padding: 13px 0;
  margin-top: 10px;
  width: 90%;
}

.sumboxesss {
  width: 100%;
  margin: 5px auto;
  padding: 5px 0px;
  border: 1px dotted #0166b3;
  border-radius: 7px;
}
.checkoxqustion{
  display: flex;
  justify-content: start;
  align-items: start;
}
.rowclass{
  margin: 0px !important;

}

.icicisum{
  margin: 0px 0px!important;
}

.same-section-med{
  /* margin-right: 25px; */
  /* width: 46%; */
  margin-top: 10px;
}

.all-med-sum{
  /* background-color: #2959b82b;
  border: 1px solid #2959b896;
  border-radius: 6px;
  padding: 11px 0;
  width: 100%; */

  background-color: aliceblue;
    border: 2px solid rgb(16 70 178 / 44%);
    border-radius: 6px;
    padding: 11px 0px;
    width: 100%;
    margin-bottom: 10px;
}

.med-person-div{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 0px;
  /* justify-content: space-between; */
  justify-content: space-around;
  /* margin-left: -10px; */
  padding-right: 8px;
}
.scroll {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 2fr));
  /* margin: 0 auto; */
  justify-content: space-around;
  width: 50%;
  float: left;
}
.center {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.mainheading {
  color: #0055a5;
  margin: 15px;
  line-height: 37px;
  font-family: 'Poppins';
}
.mainheadings {
  color: #0055a5;
  margin-top: 10px;
  position: relative;
  bottom: 10px;
  font-family: "Poppins";
}
.middelheading {
  color: #f19110;
}
.endheading {
  color: #e50318;
}

.medicalform {
  position: relative;
  top: 0px;
}
.proposaldataform {
  position: relative;
  top: 0;
}
.afterfetchform {
  position: relative;
}
.user-info {
  background: #2959b83b;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 500;
  padding: 8px;
  color: #2959b8;
  border-radius: 0.45rem;
}

/* edit details css ------------------------------ */

.edit-head {
  font-weight: 400;
  font-size: 24px;

  color: #073d81;
  display: flex;

  font-family: inherit;
}

.checked-member {
  background-color: transparent;
  border-radius: 5px 0px 0px 5px;
  border: none;
  padding: 0px 3px;
  display: flex;
  border-color: #2466b2;
  box-shadow: 0 5px 10px #2466b296;
  -webkit-filter: grayscale(0);
  margin-bottom: 15px;
  border-radius: 9px;
  /* padding: 5px; */
}

.checked-member span {
  color: rgb(36, 102, 178);
}

.checked-member input {
  margin-bottom: 0px;
}
.inputcheck input {
  margin-bottom: 0px;
}

.edit-age {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.edit-age-inp {
  width: 100px;
}

.member-ages-scrolldiv {
  height: 200px;
  overflow: scroll;
}
.reccardlinemobile{
  display: none;
}
.nextfatchtopmobile{
  display: none;
}
.colortexdesktop{
  text-align: center;
}
@media screen and (max-width: 680px) {
  .cash {
    font-size: 9pt;
    color: gray;
    letter-spacing: 1px;
    font-family: "Poppins";
}
  .nextfatchtopmobileimg{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nextfatchtopmobileplanename {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.nextfatchtopmobileimg img {
  width: 75%;
  height: 62px;
  margin: 0px;
  object-fit: contain;
  aspect-ratio: 3 / 2;
}


  .colortex {
    color: rgb(110 106 106);
    font-size: 10pt;
    margin-bottom: 0rem;
    margin-top: 0rem;
    font-family: 'Poppins';
    text-align: center;
    font-weight: 600;
    padding-left: 0px!important;
}

.mobilecoverafterfetch{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.nextcover {
  width: 100%;
  /* border: 1px dashed gray; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
}

  .blockdivmobilefetchplane {
    display: block;
    width: 100%;
}
  .flexdisplayafterfetchplane{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
.nextfatchtop{
  display: none;
}
.nextfatchtopmobile {
  display: flex;
  border: solid 1px rgb(41 89 184);
  border-radius: 11px 14px 12px 41px;
  border-width: 1px 1px 1px 11px;
  padding: 11px;
}
.recimg {
  /* border-radius: 100%; */
  width: 100%;
  height: 62px;
  margin: 0px;
  object-fit: contain;
  aspect-ratio: 3 / 2;
}
.reccardline{
  display: none;
}
.reccardlinemobile {
  display: block;
  padding-top: 28px;
  border-left: 1px dashed gray;
  border-bottom: 1px dashed gray;
  border-radius: 5px 28px 8px 14px;
}
.textaddonnamemobile{
  width: 100%;
}
.mobileflexaddonsection{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reccard-imgmobile{
  width: 50%;
}
.buttonaddonmobile{
  width: 50%;
}
.nextsection {
    border: solid 1px rgb(0 0 0 / 19%);
    border-radius: 14px;
    margin-bottom: 7px;
    padding: 23px 2px;
    margin-top: 10px;
}

.scroll {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 2fr));
    /* margin: 0 auto; */
    justify-content: space-around;
    width: 50%;
    float: none;
  }
}
/* Design Edit search (EditPED) */
.desies {
  padding: 12px 0;
  margin: 15px 0;
  background-color: #e9c2123d;
  color: black;
  border: 2px solid #e9c212;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.desiseinput {
  width: 20px;
  height: 20px;
  margin: 0 10px;
}
.desieslebel {
  margin-bottom: 0;
  color: rgb(104 96 96);
  font-weight: 600;
}
/* Eadit City Css Part */
.searchbuttondiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px 20px 28px 20px;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.3);
  background: white;
}

/* Proposaldata form */
.Proposalheader {
  color: #ef9b29;
  font-size: 1.2rem;
  position: relative;
  bottom: 10px;
}

.m-10 {
  margin-bottom: 15px;
  padding: 0 12px;
}
.Medicalheaderp {
  color: #2959b8;
  padding: 10px 10px;
  border-radius: 0.45rem;
  background-color: rgb(41 89 184 / 21%);
  font-weight: 500;
}
.backbutton {
  width: 100%;
  background-color: transparent;
  height: 130px;
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0 auto;
  padding: 0px 22px;
  /* font-size: 12pt; */
}
.fmailimagegifs {
  width: 325px;
  margin: auto;
}
.centerdivs{
  text-align: center;
}

.divanimation {
  opacity: 0; /* Initial opacity */
  transform: translateX(-50px); /* Start from the left */
  animation: fadeInSlide 0.6s forwards; /* Animation with duration and forwards fill mode */
}
.flexdisplay{
  display: flex;
  justify-content: center;
  align-items: start;
}

.qustions{
  color:#005cc8;
  font-family: 'Poppins';
  font-size: 9pt;
  font-weight: 600;
}
.listcol {
  color: #0055a5;
  font-weight: 500;
  font-family: 'Poppins';
  list-style: none;
  font-size: 11pt;
}
.yellowline {
  color: #ffae27;
  width: 20%;
  height: 0px;
  margin: 10px 0px;
  border: 1px solid #ffba48;
}


@media screen and (max-width: 500px){
  .yellowline {
    color: #ffae27;
    width: 50%;
    height: 2px;
    margin: 10px 0px;
    border: 1px solid #ffba48;
  }
}
.iilnesstag {
  color: white;
  margin: 10px 0;
  text-align: center;
  background-color: #0055a5;
  width: 100%;
  max-height: 110px;
  padding: 16px 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 71px;
  font-weight: 500;
  cursor: pointer;

}
.iinnessdivs{
  margin: 3px 0px;
  padding: 10px 15px;


}

@keyframes fadeInSlide {
  to {
    opacity: 1; /* Final opacity */
    transform: translateX(0); /* End position */
  }
}

/* Optionally, you can add a delay if needed */
.divanimation{
  animation-delay: 0.2s; /* Delay before animation starts */
}

@media screen and (max-width:524px){
  .fmailimagegifs{
    width: 90%;
    margin: auto;
  }
}
.backbutton button {
  font-weight: 500;
  background-color: transparent;
  border: transparent;
  font-size: 20px;
  font-family: "Poppins";
  color: #0166b3;
}
@media screen and (max-width: 600px){
  .edit {
    background: #f9f9f9;
    padding: 0;
    border-radius: 8px;
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid #0166b3;
}
  .top-love p {
    font-size: 9pt;
    color: rgb(0 0 0 / 81%);
    position: relative;
    top: 7px;
    font-family: "Poppins";
    /* align-content: center; */
}
  .btnmonth {
    border: none;
    border-radius: 13px;
    background: rgb(0 85 165);
    color: white;
    font-weight: 600;
    width: 100%;
    height: 43px;
    font-family: "Poppins";
    font-size: 9pt;
}
  .backbutton button {
    font-weight: 500;
    background-color: transparent;
    border: transparent;
    font-size: 15px;
    font-family: "Poppins";
    color: #0166b3;
}
}
.summary-addon{
  width: fit-content!important;
}

.summary-sum{
  padding: 7px;
  background: #fff;
    border: 1px dashed #0d5eaa52;
    border-radius: 6px;
    margin: 3px;

}

.text-right{
  margin-top: -5px;
}

.badge-warning {
  padding: 5px;
  cursor: pointer;
}


.pad-fix{
  padding: 0px!important;
}






@media screen and (max-width: 600px) {
  .backbutton {
    width: 100%;
    background-color: rgb(255, 255, 255);
    height: 45px;
    display: flex;
    justify-content: start;
    align-items: start;
    margin: 0 auto;
    padding: 0 0px;
}
}
