.all-que-div{
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
}

.pmd-all{
    border: solid 1px rgb(0 0 0 / 19%);
    padding: 10px;
    border-radius: 8px;
    box-shadow: 2px 2px 2px 1px #1e1e1e1a;
    margin: 10px 0;
}

.med-error-present{
    padding: 10px;
    border-radius: "8px";
    transition: all 0.3s ease-in-out;
    border-color: 1px solid #ff4d4d;
    box-shadow: 0px 0px 3px rgba(255, 77, 77, 0.6);  
    background-color: #ffebeb;
    border: 1px solid #ff4d4d;
}

.med-error-absent{
    padding: 10px;
    border-radius: "8px";
    transition: all 0.3s ease-in-out;
    border-color: 1px solid #ff4d4d;
    box-shadow: none; 
    background-color: transparent;
    border: 1px solid #ddd;
}

.checkbox-error {
    color: #d32f2f !important;
  }
  
  .checkbox-normal {
    color: #2959b8 !important;
  }
  
  
  .checkbox-error.Mui-checked {
    color: #d32f2f !important;
  }
  
  .checkbox-normal.Mui-checked {
    color: #2959b8 !important;
  }
  

  .label-error {
    color: rgb(164, 35, 35) !important;
    font-weight: 600 !important;
  }
  
  .label-normal {
    color: #333 !important;
    font-weight: 400 !important;
  }

  .hidden-section {
    /* display: none !important; */
    color: grey;
    padding: 10px;
  }
  
  .visible-section {
    display: block !important;
  }
  
  .clickable-box {
    min-width: 200px;
    cursor: pointer;
    cursor: pointer;
  }
  
  .sumbox-center {
    justify-content: center;
    min-width: 200px;
  }
  


  .medical-test-form {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 380px;
    
    opacity: 0;
    height: 0;
    overflow: hidden;
    transform: translateY(10px);
    transition: opacity 0.3s ease-out, transform 0.3s ease-out, height 0.3s ease-out;
  }
  
  .medical-test-form.show {
    opacity: 1;
    height: auto;
    transform: translateY(0);
  }
  
  .medical-label {
    color: #2959b8;
    margin-bottom: 8px;
  }
  
  .medical-input {
    background-color: #f5f5f5 !important;
    border-radius: 4px;
  }
  
  /* Ensure input text is grey */
  .medical-input input,
  .medical-input .MuiInputBase-input {
    color: #4a4a4aa8 !important;
  }
  
  
  .medical-input input::placeholder,
  .medical-input .MuiInputBase-input::placeholder {
    color: #4a4a4aa8 !important;
    opacity: 1 !important; 
  }
  
  
  .medical-input .MuiSvgIcon-root {
    color: #2959b8 !important;
  }
  .datepicker-field input::placeholder {
    color: #4a4a4aa8 !important;
  }
  
  .medical-input select {
    color: grey !important;
  }
  
  .medical-input {
    min-height: 40px; 
    width: 100% !important;
  
  }

  .que-one-family{
    margin-top: -10px;
    padding-left: 0px;
    padding-right: 0px;
    border: none;
    box-shadow: none;
    background-color: white;
  }

  .disabled-box {
    background-color: #f0f0f0; 
    opacity: 1; 
    color: #a0a0a0; 
    pointer-events: none; 
    cursor: not-allowed;
  }
  
 
  .sumbox.disabled-box {
    /* border: 1px solid #dcdcdc; */
    border: none;
  }

  .sumbox.disabled-box .para,
.sumbox.disabled-box .para-relation {
  color: #a0a0a0; 
}

.sumbox-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.some-padding{
  padding: 11px 0px;
}