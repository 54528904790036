.calculatorhead{
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
}
.calculatorhead h5{
   text-align: center;
}
.underdiv{
    width: 83%;
    background-color: white;
    border-radius: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: start;
    padding: 10px 0;
    margin: 0 auto;
 
}
.margincalculator{
    padding-top: 95px;
}
.CalculatorDiscription{
    font-size: 11pt;
    font-family: "Poppins";
    color: #0166B3;
    position: relative;
    top: 10px;
}
.underdiv .calculatorse{
    width: 70%;
    height: 671px;
    border-radius: 15px;
}
.resultse {
    width: 26%;
    /* height: 719px; */
    background-color: #ffffff;
    border-radius: 0px;
    padding: 1px 0;
}
.selectcalculator{
    width: 100%;
    height: 60px;
padding: 15px 25px;
    display: flex;
    justify-content: start;
    align-items: center;
    /* background-color: red; */
    position: relative;
    bottom: 10px;
    border-bottom: 1px solid rgb(218, 216, 216);
  
}
.calculatorname{
    color: #0166B3;
    background-color: #0166b334;
 padding: 10px 15px;
 border-radius: 15px;
 cursor: pointer;
    margin: 0 10px;
}
.deactivatecal{
    color: #505050;
    background-color: transparent;
 padding: 10px 15px;
 border-radius: 15px;
 cursor: pointer;
    margin: 0 10px;
}
.calculaterse {
    width: 100%;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    border: 1px solid #76acd6;
    border-radius: 15px;
    overflow: hidden;
}
.calculator{
    width: 100%;

     padding: 25px 0;
}

.graphdiv{

display: flex;
justify-content: center;
align-items: center;
}
.onecalculator {
    padding: 5px 0px;
}
.amountdives{
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px auto;
}
.amountselectordiv{
    width: 80%;
    /* background-color: red; */
    margin: 10px auto;
}
.calculateamountdiv .textword {
    font-size: 9pt;
    color: #4CAF50;
    font-family: 'Poppins';
    margin-left: 7px;
}



.coverdiv {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
}

.amountdives input {
    color: #0166B3;
    background-color: #0166b325;
    width: 90px;
    height: 30px;
    outline: none;
    font-weight: 400;
    font-family: 'Poppins';
    border: none;
    padding: 0px 6px;
    border-radius: 4px;
}
.amountdives p {
    color: rgb(128 128 128);
    font-weight: 400;
    font-family: 'Poppins';
    font-size: 12pt;
    margin-bottom: 0rem;
}

.calculateamountdiv {
    /* display: flex
; */
    /* justify-content: start; */
    padding: 10px 45px;
    /* align-items: center; */
    /* height: 290px; */
}
.calculateamountdiv p {
    font-size: 11pt;
    font-weight: 500;
    color: rgb(105 101 101 / 58%);
    font-family: 'Poppins';
}

.calculatorsresult{
    width: 99%;
    height: 44px;
    background-color: white;
    margin: 3px auto;
    border-radius: 5px;
    display: flex;
    justify-content: start;
    align-items: center;
    color: #0166b3;
    padding: 0 18px;
    cursor: pointer;
    border: 1px solid #0166b38a;
    font-size: 11pt;
    font-family: 'Poppins';

}
.headingcalculator {
    width: 80%;
    margin: 0 auto;
    margin-top: 15px;
}
.headingcalculator p {
    font-size: 9pt;
    color: rgb(165, 159, 159);
    font-family: "Poppins";
}
.headingcalculator .titlecalculator{
    font-size: 22pt;
    font-weight: 500;
    color: gray;
    font-family: 'Poppins';
}
.outerdiv_chnage{
    display: flex;
    justify-content: center;
    align-items: center;
}
.headerminwidth{
    width: 70%;
}

@media screen and (max-width: 1100px){
    .calculaterse {
        width: 100%;
        display: block;
        border: 1px solid #76acd6;
        border-radius: 15px;
    }
    .underdiv .calculatorse {
        width: 70%;
        height: 993px;
        border-radius: 15px;
    }
    .headingcalculator {
        width: 90%;
        margin: 0 auto;
        margin-top: 15px;
    }
    .underdiv {
        width: 96%;
        background-color: white;
        border-radius: 20px;
        display: flex;
        justify-content: space-evenly;
        align-items: start;
        padding: 10px 0;
        margin: 0 auto;
    }

.outerdiv_chnage {
    display: block;

}
.coverdiv{
    display: block;
}
.calculateamountdiv {
    width: 100%;
    display: block;
    justify-content: start;
    padding: 0 9px;
    align-items: center;
    margin-top: 15px;
}

}

@media screen and (max-width: 620px) {
    .underdiv {
        width: 100%;
        background-color: white;
        margin: 0 auto;
        border: none;
        border-radius: 20px;
        display: block;
      }
      .calculaterse {
        width: 100%;

        display: block;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 15px;
        padding: 0 0px;
      }
      .headingcalculator .titlecalculator {
        font-size: 15pt;
        font-weight: 500;
        color: gray;
    }
    .calculatorhead {
        width: 100%;
        margin-top: 0px;
        margin-bottom: 25px;
        /* height: 1488px; */
      }
      .amountdives p {
        color: rgb(128 128 128);
        font-weight: 500;
        font-size: 11pt;
    }
    .onecalculator {

        padding: 0px 0px;
        height: 112px;
    }
    .graphdiv {

        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px auto;
    }
    .calculateamountdiv p {
        font-weight: 500;
        color: rgb(105 101 101 / 58%);
        font-size: 10pt;
    }

      .resultse {
        width: 97%;
        /* height: 719px; */
        background-color: #ffffff;
        border-radius: 0px;
        padding: 1px 0;
        margin: 0 auto;
    }
    .calculator {
        width: 100%;
        padding: 25px 0;
    }
    .underdiv .calculatorse {
        width: 96%;
        border-radius: 15px;
    }
    .headerminwidth {
        width: 100%;
    }

    }
@media screen and (max-width: 830px) {
    .calculaterse{
        display: block;
    }
    .onecalculator {
        padding: 10px 0px;
    }
    .graphdiv {

        /* background-color: red; */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .underdiv {
        width: 98%;

        background-color: white;
        border-radius: 20px;
        display: flex;
        justify-content: space-evenly;
        align-items: start;
        padding: 10px 0;
        margin: 0 auto;
    }
}
@media screen and (max-width: 650px) {
    .underdiv {
        width: 98%;
        background-color: white;
        border-radius: 20px;
        display: block;
        padding: 10px 0;
        margin: 0 auto;
    }
    .resultse {
        width: 99%;
        background-color: #ffffff;
        border-radius: 0px;
        padding: 1px 0;
    }
    .underdiv .calculatorse {
        width: 98%;
        border-radius: 15px;
 
    }
}
