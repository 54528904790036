.typeofpolicy{
    width: 1154px;
}
.WarningAllFields {
    border: 1px solid red !important;
    box-shadow: 0px 1px 2px 0px red !important;
}

.spinner {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

@media screen and (max-width: 1024px){
    .typeofpolicy{
        width: 100%;
   }
}
.typeofheading{
    text-align: center;
    margin-bottom: 31px;

}
.typeofheading h3{
    color: #2959B8;
    font-family: 'Poppins';
    font-size: 23pt;
}
.typeofheading p{
    color: rgb(122 119 119);
    font-size: 12pt;
    font-family: 'Poppins';
}
.headingpart{
    text-align: center;
}
.headingpart h3{
    color: #2959B8;
    font-family: "Poppins";
    font-size: 22pt;
}
.headingpart p{
    color: gray;
    font-size: 13pt;
    font-family: "Poppins";
}
.cards {
    background-color: rgb(255, 255, 255);
    border-radius: 9px;
    box-shadow: 0px -1px 9px -4px rgba(0, 0, 0, 0.9);
    padding: 15px 18px;
    position: relative;
    height: 386px;
    align-content: center;
}





.head {
    width: 100%;
    text-align: center;
}
.head h3 {
    color: #2959B8;
   /* font-family: "Poopins";
    */
    font-size: 13pt;
    font-family: 'Poppins';
}
.head p {
    color: #2959B8;
    font-size: 20pt;
    margin: 0 10px;
    margin-bottom: 11px;
}

.textclass{
    margin: 10px 0;
}
.buttontext{
    text-align: center;
}
.buttontext button {
    width: 95%;
    margin: 15px auto;
    padding: 10px 0px;
    color: white;
    background-color: #2959B8;
    border-radius: 5px;
    font-family: 'Poppins';
    border: none;
    position: absolute;
    bottom: 0;
    left: 10px;
}
.listtagessterm{
    font-family: 'Poppins';
    color: rgb(122 119 119);
}
@media screen and (max-width: 768px){
    .cards {
        background-color: rgb(255, 255, 255);
        border-radius: 9px;
        box-shadow: 0px -1px 9px -4px rgba(0, 0, 0, 0.9);
        padding: 15px 18px;
        position: relative;
        height: 450px;
        align-content: center;
    }
}